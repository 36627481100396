
import Vue, { PropType } from 'vue';

import imageStoreService from '@/services/api/imageStoreService';
import ImageCrop from '@/services/api/imageStoreService/models/imageCrop';
import { ImageType } from '@/services/api/imageStoreService/constants/imageType';
import { OwnerType } from '@/services/api/imageStoreService/constants/ownerType';

interface UserInfo {
  userId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  sex: string;
  staffPositionMain: string;
  teamsWithDelimiter: string;
}

export default Vue.extend({
  name: 'UserAvatar',

  props: {
    user: {
      type: Object as PropType<UserInfo>,
      required: true,
    },
    avatarOnly: {
      type: Boolean,
      default: false,
    },
    xLarge: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String, null] as PropType<string | number | null>,
      default: 36,
    },
    avatarClass: {
      type: String,
      default: '',
    },
    nameClass: {
      type: String,
      default: '',
    },
    withTooltip: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    initials(): string {
      return [this.user.firstName, this.user.lastName]
        .filter(Boolean)
        .map((str) => str?.charAt(0))
        .join('');
    },
    src(): string {
      const crop = new ImageCrop(120, 120);
      return imageStoreService.getDownloadUrl(OwnerType.User, this.user.userId, ImageType.Avatar, crop);
    },
    fullName(): string {
      return `${this.user.lastName} ${this.user.firstName}`;
    },
  },
});
