// eslint-disable-next-line import/prefer-default-export,no-shadow
export enum ImageType {
  Avatar = 'avatar',
  Logo = 'logo',
  Cover = 'cover',
  UniversalCover = 'universal_cover',
  Icon = 'icon',
  Image = 'image',
  Author = 'author',
}
