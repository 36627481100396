import ImageCrop from '@/services/api/imageStoreService/models/imageCrop';

const { VUE_APP_GOGHA_API_ADDRESS = 'http://localhost:3000/' } = process.env;

const baseUrl = VUE_APP_GOGHA_API_ADDRESS.replace(/\/$/, '');

export default {
  getUploadUrl() {
    // TODO: Реализовать
    throw new Error('getUploadUrl method is not implemented');
  },
  getDownloadUrl(type: string, uuid: string, name: string, size: ImageCrop) {
    return `${baseUrl}/${type}/${uuid}/${name}/${size.width}x${size.height}`;
  },
};
