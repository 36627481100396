
import Vue from 'vue';

export default Vue.extend({
  name: 'TTActionDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [Number, String],
      default: 536,
    },
  },
  computed: {
    isShowDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        if (!value) this.$emit('input', value);
      },
    },
  },
});
