export default class ImageCrop {
  width: number;

  height: number;

  constructor(width: number, height = width) {
    this.width = width;
    this.height = height;
  }
}
